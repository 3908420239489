import { RegionLandingPageTransport } from '../transports'
import { WithContext, Product } from 'schema-dts'

export const getProductSchemaHTML = (
    landingPageTransport: RegionLandingPageTransport | null,
): { __html: string } | null => {
    if (
        !landingPageTransport ||
        !landingPageTransport.topRatedObjects ||
        !landingPageTransport.topRatedObjects.averageRating ||
        !landingPageTransport.topRatedObjects.totalRatingCount ||
        landingPageTransport.topRatedObjects.totalRatingCount <= 0 ||
        landingPageTransport.topRatedObjects.averageRating < 1
    ) {
        return null
    }

    const schema: WithContext<Product> = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: landingPageTransport.hero.description.title,
        description: landingPageTransport.head.description,
        brand: {
            '@type': 'Brand',
            name: 'Traum-Ferienwohnungen',
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            bestRating: '5',
            worstRating: '1',
            ratingValue: landingPageTransport.topRatedObjects.averageRating,
            reviewCount: landingPageTransport.topRatedObjects.totalRatingCount,
        },
    }

    if (landingPageTransport.hero.slide?.image) {
        schema.image = landingPageTransport.hero.slide?.image?.url
    }

    return {
        __html: JSON.stringify(schema),
    }
}
